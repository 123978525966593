import {Form, Button, Typography, message} from 'antd';

import apiClient from 'api';

import {baseUrl, getErrorMessage} from 'utils';
import EmailFormItem from 'components/forms/EmailFormItem';

type ForgotPasswordRequest = {
    email: string
}

const ForgotPasswordForm = (props: {
    onFinish: (email: string) => void
}) => {
    async function onFinish(values: ForgotPasswordRequest) {
        try {
            const res = await fetch(
                apiClient.buildUrl('/auth/forgot_password'),
                {
                    method: 'POST',
                    body: JSON.stringify({...values, base_url: baseUrl()}),
                    headers: {'Content-Type': 'application/json'}
                }
            );
            if (res.status !== 200) {
                message.error(res.statusText);
            } else {
                props.onFinish(values.email);
            }
        } catch (e) {
            message.error(<><b>[Unknown Network Error]</b> {getErrorMessage(e)}</>);
        }
    }

    return (
        <Form
            name="login"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title level={2}>Forgot Password</Typography.Title>
            <EmailFormItem extra={'Enter the email that you used to sign up.'} />

            <Form.Item>
                <Button type="primary" htmlType="submit" className="forgot-password-form-button">
                    Send Reset Email
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ForgotPasswordForm;
