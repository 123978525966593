import {Link} from 'react-router-dom';
import {Layout} from 'antd';
import LogoLight from 'assets/logo-light';

import styles from './styles.module.scss';

const SiderLogo = () => {
    return (
        <Layout.Header className={styles.siteLogo}>
            <Link to={'/'}>
                <LogoLight />
            </Link>
        </Layout.Header>
    );
}

export default SiderLogo;
