import {Typography} from 'antd';
import ForgotPasswordForm from './ForgotPasswordForm';

import AuthPage from 'components/AuthPage';
import {useState} from 'react';

function emailDefault() {
    return (window.location.hash === '#sent') ? 'test@example.org' : null;
}

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState<string | null>(emailDefault());

    if (email) {
        return (
            <AuthPage>
                <Typography.Title level={2}>Reset Email Sent</Typography.Title>
                <Typography.Text>Please check {email} for a message containing a reset link.</Typography.Text>
            </AuthPage>
        )
    }

    return (
        <AuthPage>
            <ForgotPasswordForm onFinish={setEmail}/>
        </AuthPage>
    );
}

export default ForgotPasswordPage;
