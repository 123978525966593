import type {Account} from '@gauged/gauged-js';

import apiClient from 'api';

import {baseUrl} from 'utils';

type OnboardingResponse = {
    location: string,
    account: Account,
}

export async function stripeOnboarding(
) {
    const returnUrl = `${baseUrl()}/onboarding/stripe/completed`;
    const refreshUrl = `${baseUrl()}/onboarding?action=refresh&type=stripe`;

    return apiClient
        .post<OnboardingResponse>('/apps/stripe/onboarding', {
            refresh_url: refreshUrl,
            return_url: returnUrl,
        });
}
