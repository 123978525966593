import React from 'react';
import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom';
import {App} from 'antd';

import Page from 'components/Page';
import ErrorBoundary from 'routing/ErrorBoundary';

import {me} from 'services/me';
import {users} from 'services/users';

import {forms} from 'loaders/forms';
import {confirmEmail} from 'loaders/confirmEmail';

import ProfilePage from 'pages/profile/ProfilePage';
import UsersPage from 'pages/users/UsersPage';
import HomePage from 'pages/home/HomePage';

import LoginPage from 'pages/auth/login/LoginPage';
import RegisterPage from 'pages/auth/register/RegisterPage';
import ForgotPasswordPage from 'pages/auth/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';
import ConfirmEmailPage from 'pages/auth/confirmEmail/ConfirmEmailPage';
import OnboardingPage from './pages/onboarding/OnboardingPage';
import StripeCompletedPage from './pages/onboarding/stripe/StripeCompletedPage';
import FormPage from './pages/forms/FormPage';


const router = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage/>
    },
    {
        path: '/register',
        element: <RegisterPage/>
    },
    {
        path: '/forgot_password',
        element: <ForgotPasswordPage/>,
    },
    {
        path: '/reset_password',
        element: <ResetPasswordPage/>,
    },
    {
        path: '/confirm_email',
        element: <ConfirmEmailPage/>,
        loader: confirmEmail,
    },
    {
        path: '/onboarding',
        element: <OnboardingPage/>,
        errorElement: <ErrorBoundary/>,
        loader: me,
    },
    {
        path: '/onboarding/stripe/completed',
        element: <StripeCompletedPage/>,
        errorElement: <ErrorBoundary/>,
        loader: me,
    },
    {
        path: '',
        element: <Page/>,
        errorElement: <ErrorBoundary/>,
        loader: me,
        children: [
            {
                path: '/',
                element: <HomePage/>,
            },
            {
                path: '/users',
                element: <UsersPage/>,
                loader: users,
            },
            {
                path: '/forms',
                element: <FormPage/>,
                loader: forms,
            },
            {
                path: '/profile',
                element: <ProfilePage/>,
            },
            {
                path: '*',
                element: <Navigate to={'/'} replace/>,
            },
        ]
    }
]);

function MainApp() {
    return (
        <App>
            <RouterProvider router={router}/>
        </App>
    );
}

export default MainApp;
