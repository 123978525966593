import {Button, message, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {deleteUser, type User} from '@gauged/gauged-js';
import api from 'api';


const DeleteUserButton = (props: {
    user: User
    onDelete?: (user: User) => void;
}) => {
    const [messageApi, contextHolder] = message.useMessage();

    async function onConfirm() {
        try {
            await deleteUser(api, props.user.id);
            messageApi.success(`User '${props.user.email}' successfully deleted.`);
            props.onDelete?.(props.user);
        } catch (e) {
            return messageApi.error(`Failed to delete user: ${props.user.email}`);
        }
    }

    return (
        <Popconfirm
            title="Delete the user"
            description="Are you sure to delete this user?  This action cannot be undone."
            onConfirm={onConfirm}
            okText="Delete"
            cancelText="Cancel"
        >
            {contextHolder}
            <Button shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
    );
}

export default DeleteUserButton;
