import {Typography} from 'antd';
import {Link, useNavigate} from 'react-router-dom';

import type {User, AuthToken} from '@gauged/gauged-js';

import apiClient from 'api';
import {useAppDispatch} from 'store';

import {setCurrentUser} from 'features/currentUser/currentUserSlice';
import AuthPage from 'components/AuthPage';
import LoginForm from './LoginForm';


function redirectURI() {
    const params = new URLSearchParams(document.location.search);
    const location = params.get('redirect_uri');
    return location ? location : '/';
}

const LoginPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function onLogin(user: User, token: AuthToken) {
        dispatch(setCurrentUser(user));
        apiClient.accountId = user.account_id;
        apiClient.setToken(token.access_token);
        navigate(redirectURI())
    }

    return (
        <AuthPage>
            <LoginForm onLogin={onLogin}/>
            <Typography.Text>Don't have an account? <Link to={'/register'}>Sign Up</Link></Typography.Text>
            <Typography.Text><Link to={'/forgot_password'}>Forgot your password?</Link></Typography.Text>
        </AuthPage>
    );
}

export default LoginPage;
