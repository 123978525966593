import {Link, useNavigate} from 'react-router-dom';

import {Avatar, Dropdown, Flex, MenuProps} from 'antd';
import {UserOutlined} from '@ant-design/icons';

import apiClient from 'api';
import {useCurrentUser} from '../../../hooks/useCurrentUser';
import {SYSTEM_ACCOUNT} from '../../../utils';

const Profile = () => {
    const currentUser = useCurrentUser();
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            label: <Link to="/profile">Profile</Link>,
            key: 'profile',
        },
        {
            type: 'divider',
        },
        {
            label: 'Logout',
            key: 'logout',
        },
    ];

    const onClick: MenuProps['onClick'] = ({key}) => {
        if (key === 'logout') {
            apiClient.accountId = SYSTEM_ACCOUNT;
            apiClient.clearToken();
            navigate('/login')
        }
    };

    return (
        <Dropdown menu={{items: items, onClick}} trigger={['click']}>
            <Flex align={'center'} style={{cursor: 'pointer'}}>
                <div style={{marginRight: '8px'}}>{currentUser?.email}</div>
                <Avatar icon={<UserOutlined/>} />
            </Flex>
        </Dropdown>
    )
}

export default Profile;
