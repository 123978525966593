import React from 'react';

const AuthPage = (props: {
    children: React.ReactNode
}) => (
        <div className={'auth-page'}>
            <div className={'auth-container pb-70'}>
                {props.children}
            </div>
        </div>
    );

export default AuthPage;
