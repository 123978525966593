import { Layout } from 'antd';
import SideMenu from './SideMenu';
import SiteLogo from './SiteLogo';

import styles from './styles.module.scss';


const MainSider = (props: {
    collapsed: boolean,
    onCollapse: (value: boolean) => void
}) => {
    return (
        <Layout.Sider
            className={styles.sider}
            width={290}
            collapsedWidth={85}
            collapsible
            collapsed={props.collapsed}
            onCollapse={props.onCollapse}
        >
            <SiteLogo />
            <SideMenu />
        </Layout.Sider>
    );
}

export default MainSider;
