import React from 'react';
import ReactDOM from 'react-dom/client';

import {ConfigProvider} from 'antd';
import { Provider } from 'react-redux';

import './scss/index.scss';
import MainApp from './MainApp';

import store from './store';
import theme from './theme.json';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ConfigProvider wave={{ disabled: true }} theme={theme}>
            <Provider store={store}>
                <MainApp/>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>
);
