import type {Role, Account} from '@gauged/gauged-js';
import {SYSTEM_ACCOUNT} from 'utils';
import {Tag} from 'antd';

function tagColor(account: string|undefined, role: Role) {
    if (role.name === 'super-admin' && account === SYSTEM_ACCOUNT) {
        return 'red';
    }
    if (role.name === 'admin') {
        return 'volcano';
    }
    return 'geekblue';
}

const RolesBadges = (props: {account: Account|undefined, roles: Role[]|undefined}) => {
    if (!props.roles) {
        return null;
    }
    return (
        <>
            {props.roles.map(role => {
                return <Tag key={role.id} color={tagColor(props.account?.id, role)}>{role.name}</Tag>
            })}
        </>
    );
}

export default RolesBadges;
