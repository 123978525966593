import {useEffect, useState} from 'react';
import {Form, Input, Select} from 'antd';
import type {FormInstance} from 'antd';

import {type Role} from '@gauged/gauged-js';

import {roles as getRoles} from 'services/roles';



const AddUserForm = (props: {form: FormInstance}) => {
    const [roles, setRoles] = useState<Role[]>([])

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    useEffect( () => {
        getRoles().then(roles => {
            setRoles(roles);
        })
    }, [props.form]);

    const options = roles.map(role => {
        return {value: role.name, label: role.name}}
    );

    return (
        <Form
            {...formItemLayout}
            form={props.form}
        >
            <Form.Item
                label="Email"
                name={'email'}
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="First Name" name={'first_name'}>
                <Input />
            </Form.Item>
            <Form.Item label="Last Name" name={'last_name'}>
                <Input />
            </Form.Item>
            <Form.Item label="Role(s)" name={'roles'}>
                <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    options={options}
                />
            </Form.Item>
        </Form>
    );
}

export default AddUserForm;
