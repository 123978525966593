import type {User} from '@gauged/gauged-js';
import store from 'store';

import {setCurrentUser} from 'features/currentUser/currentUserSlice';

import apiClient from 'api';


export async function me() {
    const user = await apiClient.get<User>('/auth/me?expand=roles&expand=account');
    store.dispatch(setCurrentUser(user));
    apiClient.accountId = user.account_id;
    return user;
}

export async function updateMe(
    data: {first_name?: string|null, last_name?: string|null},
) {
    const user = await apiClient.patch<User>('/auth/me?expand=roles&expand=account', data)
    store.dispatch(setCurrentUser(user));
    return user;
}
