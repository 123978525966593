import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Typography} from 'antd';

import {getFirstQueryParam} from 'utils';
import AuthPage from 'components/AuthPage';
import AuthPageError from 'components/AuthPageError';

import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = () => {
    const token = getFirstQueryParam('t');
    const [error, setError] = useState<string|null>(token ? null : 'Missing token');
    const [done, setDone] = useState(window.location.hash === '#reset');

    function onReset() {
        setDone(true);
    }

    function onError(_: number, message: string) {
        setError(message);
    }

    if (error || !token) {
        return (
            <AuthPageError>
                <Typography.Text style={{textAlign:'center'}}>Your reset password request couldn't be processed.  Please try again.</Typography.Text>
                <Typography.Text className={'text-danger'}>[{error}]</Typography.Text>
                <Link to={'/reset_password'}>Forgot Password</Link>
            </AuthPageError>
        )
    }

    if (done) {
        return (
            <AuthPage>
                <Typography.Title level={2}>Your password was successfully reset.</Typography.Title>
                <Button type="primary" block href={'/'}>Continue To Dashboard</Button>
            </AuthPage>
        );
    }

    return (
        <AuthPage>
            <ResetPasswordForm token={token} onReset={onReset} onError={onError}/>
            <Typography.Text><Link to={'/login'}>Log In</Link></Typography.Text>
        </AuthPage>
    );
}

export default ResetPasswordPage;
