import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';

import { Layout } from 'antd';
import MainSider from 'components/layout/sider/MainSider';
import MainHeader from 'components/layout/header/MainHeader';

const Page = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <MainSider collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} />
            <Layout>
                <MainHeader />
                <Layout.Content style={{ margin: '0 16px' }}>
                    <Outlet/>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center' }}>
                    Proprietary & Confidential
                </Layout.Footer>
            </Layout>
        </Layout>
    );
}
export default Page
