import {useState} from 'react';
import {useLoaderData, useSearchParams} from 'react-router-dom';
import {Form, Modal, Table} from 'antd';

import {fetchUsers, type User} from '@gauged/gauged-js';

import RolesBadges from 'components/RolesBadges';
import PageHeader from 'components/PageHeader';
import AddUserForm from './AddUserForm';
import {addUser} from 'services/users';
import DeleteUserButton from './DeleteUserButton';
import api from 'api';

const {Column} = Table;

function renderName(_: any, record: User) {
    if (record.first_name && record.last_name) {
        return `${record.first_name} ${record.last_name}`;
    }
    if (record.first_name) {
        return record.first_name;
    }
    if (record.last_name) {
        return record.last_name;
    }
    return '-';
}


type FieldValues = {
    email: string,
    first_name: string | null | undefined,
    last_name: string | null | undefined,
    roles: string[]
};


const Heading = (props: {
    onSave: (values: FieldValues) => void
}) => {
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(false);

    function onOk() {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                props.onSave(values as FieldValues);
                setShowModal(false)
            })
            .catch(() => {});
    }

    return (
        <PageHeader title={'Users'} onClick={() => setShowModal(true)}>
            <Modal
                title="Add New User"
                open={showModal}
                onOk={onOk}
                onCancel={() => setShowModal(false)}
            >
                <AddUserForm form={form} />
            </Modal>
        </PageHeader>
    );
};

const UsersPage = () => {
    const setSearchParams = useSearchParams()[1]

    const data = useLoaderData() as {
        total: number,
        offset: number,
        limit: number,
        users: User[]
    };

    function onChange(page: number, pageSize: number) {
         setSearchParams({
             offset: Math.max((page - 1) * pageSize, 0).toString(),
             limit: pageSize.toString()
         });
    }

    async function onSave(values: FieldValues) {
        return addUser(values);
    }

    async function onDelete() {
        data.users = await fetchUsers(api);
    }

    return (
        <div>
            <Heading onSave={onSave}/>
            <Table
                dataSource={data.users}
                rowKey={record => record.id}
                pagination={{
                    defaultCurrent: 1,
                    total: data.total,
                    pageSize: data.limit,
                    onChange: onChange
                }}
            >
                <Column
                    title={'Name'}
                    key={'name'}
                    render={renderName}
                />
                <Column
                    title={'Email'}
                    key={'email'}
                    dataIndex={'email'}
                />
                <Column
                    title={'Roles'}
                    key={'roles'}
                    render={(_, record: User) => (
                        <RolesBadges account={record.account} roles={record.roles} />
                    )}
                    width={'1%'}
                />
                <Column
                    key={'actions'}
                    render={(_, record: User) => (
                        <DeleteUserButton user={record} onDelete={onDelete}/>
                    )}
                    width={'1%'}
                />
            </Table>
        </div>
    );
}

export default UsersPage;
