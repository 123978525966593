const LogoLight = () => (
    <svg viewBox="0 0 508 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M183.806 30.8935H206.094V57.3552C202.882 59.7074 199.454 61.4141 195.811 62.4755C192.196 63.5368 188.41 64.0675 184.452 64.0675C179.288 64.0675 174.584 63.2643 170.339 61.658C166.122 60.0229 162.508 57.7855 159.496 54.9457C156.484 52.0772 154.16 48.6924 152.525 44.7913C150.89 40.8615 150.073 36.6018 150.073 32.0122C150.073 27.3366 150.847 23.0482 152.396 19.1471C153.945 15.2173 156.154 11.8325 159.023 8.99268C161.92 6.15289 165.405 3.94416 169.478 2.36649C173.58 0.788831 178.155 0 183.204 0C185.814 0 188.267 0.215136 190.561 0.645407C192.856 1.07568 194.965 1.66372 196.886 2.40952C198.837 3.15533 200.615 4.04456 202.222 5.07721C203.828 6.10986 205.262 7.22857 206.525 8.43333L202.308 14.8444C201.648 15.8483 200.788 16.4651 199.726 16.6945C198.694 16.924 197.575 16.6659 196.37 15.9201C195.223 15.2316 194.133 14.6436 193.1 14.1559C192.096 13.6396 191.063 13.2237 190.002 12.9082C188.941 12.5926 187.822 12.3631 186.646 12.2197C185.47 12.0763 184.15 12.0046 182.687 12.0046C179.962 12.0046 177.51 12.4922 175.33 13.4675C173.15 14.4141 171.285 15.7623 169.736 17.5121C168.216 19.2618 167.04 21.3702 166.208 23.8371C165.376 26.2753 164.96 29.0003 164.96 32.0122C164.96 35.3397 165.419 38.2942 166.337 40.8758C167.255 43.4575 168.546 45.6375 170.21 47.416C171.902 49.1944 173.91 50.5569 176.233 51.5035C178.586 52.4215 181.182 52.8804 184.021 52.8804C185.771 52.8804 187.349 52.7083 188.754 52.3641C190.189 52.0199 191.58 51.5609 192.928 50.9872V41.4782H186.732C185.814 41.4782 185.097 41.2344 184.581 40.7467C184.064 40.2591 183.806 39.6424 183.806 38.8966V30.8935Z"
            fill="white"/>
        <path
            d="M248.081 39.4129L242.273 22.2451C241.842 21.155 241.384 19.8786 240.896 18.4156C240.408 16.924 239.921 15.3177 239.433 13.5966C239.003 15.3464 238.544 16.9671 238.056 18.4587C237.568 19.9503 237.109 21.2411 236.679 22.3311L230.914 39.4129H248.081ZM271.187 63.379H259.914C258.652 63.379 257.633 63.0922 256.859 62.5185C256.084 61.9161 255.496 61.156 255.095 60.2381L251.395 49.3092H227.557L223.857 60.2381C223.542 61.0412 222.968 61.7727 222.136 62.4325C221.333 63.0635 220.329 63.379 219.124 63.379H207.765L232.032 0.688436H246.92L271.187 63.379Z"
            fill="white"/>
        <path
            d="M300.694 51.8908C302.644 51.8908 304.38 51.5753 305.9 50.9442C307.449 50.2844 308.754 49.3522 309.815 48.1474C310.877 46.9427 311.68 45.4797 312.225 43.7587C312.799 42.0089 313.085 40.0296 313.085 37.8209V0.688436H327.629V37.8209C327.629 41.6647 326.998 45.1929 325.735 48.4056C324.502 51.6183 322.723 54.3864 320.4 56.7098C318.077 59.0333 315.251 60.8404 311.924 62.1313C308.596 63.4221 304.853 64.0675 300.694 64.0675C296.506 64.0675 292.748 63.4221 289.42 62.1313C286.093 60.8404 283.268 59.0333 280.944 56.7098C278.621 54.3864 276.842 51.6183 275.609 48.4056C274.375 45.1929 273.759 41.6647 273.759 37.8209V0.688436H288.302V37.7779C288.302 39.9866 288.574 41.9659 289.119 43.7156C289.693 45.4367 290.51 46.914 291.572 48.1474C292.633 49.3522 293.924 50.2844 295.444 50.9442C296.993 51.5753 298.743 51.8908 300.694 51.8908Z"
            fill="white"/>
        <path
            d="M369.109 30.8935H391.397V57.3552C388.184 59.7074 384.756 61.4141 381.113 62.4755C377.499 63.5368 373.713 64.0675 369.754 64.0675C364.591 64.0675 359.887 63.2643 355.641 61.658C351.425 60.0229 347.81 57.7855 344.798 54.9457C341.787 52.0772 339.463 48.6924 337.828 44.7913C336.193 40.8615 335.376 36.6018 335.376 32.0122C335.376 27.3366 336.15 23.0482 337.699 19.1471C339.248 15.2173 341.457 11.8325 344.325 8.99268C347.222 6.15289 350.708 3.94416 354.781 2.36649C358.883 0.788831 363.458 0 368.506 0C371.117 0 373.569 0.215136 375.864 0.645407C378.159 1.07568 380.267 1.66372 382.189 2.40952C384.14 3.15533 385.918 4.04456 387.524 5.07721C389.131 6.10986 390.565 7.22857 391.827 8.43333L387.611 14.8444C386.951 15.8483 386.09 16.4651 385.029 16.6945C383.996 16.924 382.878 16.6659 381.673 15.9201C380.525 15.2316 379.435 14.6436 378.403 14.1559C377.399 13.6396 376.366 13.2237 375.305 12.9082C374.243 12.5926 373.125 12.3631 371.949 12.2197C370.773 12.0763 369.453 12.0046 367.99 12.0046C365.265 12.0046 362.813 12.4922 360.632 13.4675C358.452 14.4141 356.588 15.7623 355.039 17.5121C353.519 19.2618 352.343 21.3702 351.511 23.8371C350.679 26.2753 350.263 29.0003 350.263 32.0122C350.263 35.3397 350.722 38.2942 351.64 40.8758C352.558 43.4575 353.849 45.6375 355.512 47.416C357.205 49.1944 359.213 50.5569 361.536 51.5035C363.888 52.4215 366.484 52.8804 369.324 52.8804C371.074 52.8804 372.651 52.7083 374.057 52.3641C375.491 52.0199 376.882 51.5609 378.231 50.9872V41.4782H372.035C371.117 41.4782 370.4 41.2344 369.883 40.7467C369.367 40.2591 369.109 39.6424 369.109 38.8966V30.8935Z"
            fill="white"/>
        <path
            d="M415.807 11.8755V26.5047H435.513V37.2615H415.807V52.192H441.537V63.379H401.178V0.688436H441.537V11.8755H415.807Z"
            fill="white"/>
        <path
            d="M507.389 32.0122C507.389 36.5444 506.601 40.7324 505.023 44.5762C503.474 48.3912 501.28 51.7043 498.44 54.5154C495.6 57.2979 492.172 59.4779 488.156 61.0556C484.169 62.6046 479.737 63.379 474.861 63.379H450.508V0.688436H474.861C479.737 0.688436 484.169 1.47727 488.156 3.05493C492.172 4.63259 495.6 6.81264 498.44 9.59506C501.28 12.3775 503.474 15.6906 505.023 19.5343C506.601 23.3494 507.389 27.5087 507.389 32.0122ZM492.502 32.0122C492.502 28.9143 492.1 26.1175 491.297 23.6219C490.494 21.1263 489.332 19.018 487.812 17.2969C486.32 15.5472 484.485 14.2133 482.305 13.2954C480.125 12.3488 477.643 11.8755 474.861 11.8755H465.137V52.192H474.861C477.643 52.192 480.125 51.733 482.305 50.8151C484.485 49.8685 486.32 48.5347 487.812 46.8136C489.332 45.0638 490.494 42.9411 491.297 40.4456C492.1 37.95 492.502 35.1389 492.502 32.0122Z"
            fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M112.605 16.4424C126.363 28.1643 135.423 45.2383 136.555 64.4457H106.639C104.405 53.7101 99.453 44.0821 92.6034 36.4444L112.605 16.4424Z"
              fill="url(#paint0_linear_27_217)"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M35.1025 21.8223C17.5873 28.4889 4.16635 44.5036 0 64.3078C1.51571 39.4584 16.2968 18.1958 37.3515 7.50547L35.1025 21.8223Z"
              fill="url(#paint1_linear_27_217)"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M68.2734 0.128662C84.2733 0.128662 98.9879 5.62295 110.638 14.8263L90.8686 34.5957C81.0754 24.603 67.8561 18.4732 53.3058 18.4732C47.9254 18.4732 42.7276 19.3134 37.8166 20.8748L40.1256 6.17541C48.7104 2.29334 58.238 0.128662 68.2734 0.128662Z"
              fill="url(#paint2_linear_27_217)"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M88.9304 22.1421L47.9013 62.6277C47.731 62.8197 47.5467 63.0044 47.3416 63.1745C44.9995 65.1807 41.5134 64.8685 39.5504 62.4688C37.5944 60.0693 37.901 56.4958 40.2431 54.4823C40.5969 54.1775 40.9781 53.9304 41.3798 53.7266L88.9304 22.1421Z"
              fill="#4EA8FE"/>
        <defs>
            <linearGradient id="paint0_linear_27_217" x1="22.3783" y1="16.9126" x2="133.674" y2="69.1422"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#4DA9FF"/>
                <stop offset="1" stopColor="#FF20CE"/>
            </linearGradient>
            <linearGradient id="paint1_linear_27_217" x1="22.3783" y1="16.9126" x2="133.674" y2="69.1422"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#4DA9FF"/>
                <stop offset="1" stopColor="#FF20CE"/>
            </linearGradient>
            <linearGradient id="paint2_linear_27_217" x1="22.3783" y1="16.9126" x2="133.674" y2="69.1422"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#4DA9FF"/>
                <stop offset="1" stopColor="#FF20CE"/>
            </linearGradient>
        </defs>
    </svg>
);

export default LogoLight;
