import {useLoaderData, useSearchParams} from 'react-router-dom';
import {Table} from 'antd';

import {Form} from '@gauged/gauged-js';

function hasEmail(forms: Form[]) {
    for (const form of forms) {
        if (form.data.email) {
            return true;
        }
    }
    return false;
}

const FormPage = () => {
    const setSearchParams = useSearchParams()[1]

    const data = useLoaderData() as {
        total: number,
        offset: number,
        limit: number,
        data: Form[]
    };

    function onChange(page: number, pageSize: number) {
        setSearchParams({
            offset: Math.max((page - 1) * pageSize, 0).toString(),
            limit: pageSize.toString()
        });
    }


    return (
        <div>
            <Table
                dataSource={data.data}
                rowKey={record => record.id}
                pagination={{
                    defaultCurrent: 1,
                    total: data.total,
                    pageSize: data.limit,
                }}

            >
                <Table.Column
                    title={'Form'}
                    key={'__form__'}
                />
                {hasEmail(data.data) ?
                    <Table.Column
                        title={'Email'}
                        key={'data.email'}
                    /> : null }
                <Table.Column
                    title={'IP Address'}
                    key={'ip_address'}
                />
                <Table.Column
                    title={'Country'}
                    key={'country'}
                />
            </Table>
        </div>
    );
}

export default FormPage;
