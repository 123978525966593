import {Link, useLoaderData} from 'react-router-dom';

import {Button, Flex, Typography} from 'antd';

import AuthPage from 'components/AuthPage';
import AuthPageError from 'components/AuthPageError';

import {type User} from '@gauged/gauged-js';


const ConfirmEmailPage = () => {
    const user = useLoaderData() as User | null;

    if (!user) {
        return (
            <AuthPageError>
                <Typography.Text>Your email verification request couldn't be processed. Make sure the link is
                    correct.</Typography.Text>
                <Link to={'/login'}>Log In</Link>
            </AuthPageError>
        )
    }

    return (
        <AuthPage>
            <Flex vertical align={'center'} gap={'large'}>
                <Typography.Title level={2}>This email is verified.</Typography.Title>
                <Typography.Text>You're all set! Your address <strong>{user.email}</strong> is verified.</Typography.Text>
                <Button type="primary" block href={'/'}>Continue To Dashboard</Button>
            </Flex>
        </AuthPage>
    );
}

export default ConfirmEmailPage;
