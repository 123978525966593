import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Typography} from 'antd';

import AuthPage from 'components/AuthPage';
import RegisterForm from './RegisterForm';

const {Text} = Typography;

const RegisterPage = () => {
    const [done, setDone] = useState(window.location.hash === '#registered');

    function onRegister() {
        window.location.hash = '#registered';
        setDone(true);
    }

    if (done) {
        return (
            <AuthPage>
                <Typography.Title level={2}>Thank you for registering!</Typography.Title>
                <Typography.Text>Please check your email to confirm your address.</Typography.Text>
                <Button type="primary" block href={'/'}>Continue To Dashboard</Button>
            </AuthPage>
        );
    }

    return (
        <AuthPage>
            <RegisterForm onRegister={onRegister}/>
            <Text>Already registered? <Link to={'/login'}>Log In</Link></Text>
        </AuthPage>
    );
}

export default RegisterPage;
