import {Button, Descriptions, Flex, Typography} from 'antd';
import {useLoaderData} from 'react-router-dom';
import type {Account} from '@gauged/gauged-js';

type StripeAccount = {
    id: string;
    email: string;
    country: string;
    details_submitted: boolean
}

const StripeCompletedPage = () => {
    const account = (useLoaderData() as Account & {data: StripeAccount});
    const stripeAccount = account?.data;
    return (
        <Flex vertical align={'center'}>
            <div style={{width: '100%', maxWidth: '400px', margin: '2rem auto'}}>
                <Typography.Title>Stripe onboarding completed.</Typography.Title>
                {account ?
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="Name">{account.name}</Descriptions.Item>
                        <Descriptions.Item label="ID">{account.id}</Descriptions.Item>
                        <Descriptions.Item label="Country">{stripeAccount?.country}</Descriptions.Item>
                        <Descriptions.Item label="Details Submitted">{stripeAccount?.details_submitted}</Descriptions.Item>
                    </Descriptions> : null
                }
                <Button style={{marginTop:'1rem'}} type="primary" block href={'/'}>Continue To Dashboard</Button>
            </div>
        </Flex>
    );
}

export default StripeCompletedPage;
