import {useEffect} from 'react';

import {Form, Input} from 'antd';
import type {FormInstance} from 'antd';

import {type User} from '@gauged/gauged-js';


const EditProfileForm = (props: {form: FormInstance, user: User}) => {
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    useEffect(() => {
        props.form.setFieldsValue({
            first_name: props.user.first_name,
            last_name: props.user.last_name
        });
    }, [props.form, props.user]);

    return (
        <Form
            {...formItemLayout}
            form={props.form}
        >
            <Form.Item label="First Name" name={'first_name'}>
                <Input />
            </Form.Item>
            <Form.Item label="Last Name" name={'last_name'}>
                <Input />
            </Form.Item>
        </Form>
    );
}

export default EditProfileForm;
