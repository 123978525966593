import {useRouteError} from 'react-router-dom';
import {isString} from 'utils';

function ErrorBoundary() {
    const error = useRouteError() as Response;
    return (
        <div style={{textAlign:'center', margin:'3rem'}}>
            <b>An unexpected error occurred.  Please reload the page.</b>
            <p>{isString(error) ? error.toString() : JSON.stringify(error)}</p>
        </div>
    );
}

export default ErrorBoundary;