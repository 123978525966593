import React from 'react';
import {Flex} from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import AuthPage from './AuthPage';

const AuthPageError = (props: {
    children: React.ReactNode;
}) => {
    return (
        <AuthPage>
            <Flex vertical align={'center'} gap={'large'}>
                <WarningOutlined/>
                {props.children}
            </Flex>
        </AuthPage>
    );
}

export default AuthPageError;
