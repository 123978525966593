import React from 'react';
import {Button} from 'antd';
import styles from './VendorLink.module.scss';

// const {useBreakpoint} = Grid;

const VendorLink = (props: {
    onClick: () => void,
    alt: string,
    src: string,
}) => {
    // const breakpoints = useBreakpoint();

    return (
        <Button type={'link'} className={styles.button} onClick={props.onClick}>
            <img className={styles.img} alt={props.alt} src={props.src}/>
        </Button>
    );
}

export default VendorLink;
