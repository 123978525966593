import React, {useState} from 'react';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {Flex, Layout, Spin, theme, Typography} from 'antd';

import Profile from 'components/layout/header/Profile';

import VendorLink from './VendorLink';
import {stripeOnboarding} from './services';


const OnboardingPage = () => {
    const [busy, setBusy] = useState(false);
    const currentUser = useCurrentUser();
    const {
        token: { colorBgContainer},
    } = theme.useToken();

    if (!currentUser) {
        return null;
    }

    async function onboardStripe() {
        setBusy(true);
        const data = await stripeOnboarding();
        window.location.href = data['location'];
        setTimeout(() => {
            setBusy(false);
        }, 5000);
    }

    return (
        <Layout>
            <Layout.Header style={{
                padding: '0 16px',
                background: colorBgContainer,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end'
            }}>
                <Profile/>
            </Layout.Header>
            <Layout.Content>
                <Flex className={'onboarding-page w-100 my-5rem'} align={'center'} vertical gap={'10px'}>
                    <Typography.Title level={1}>Let's Get Started</Typography.Title>
                    <Typography.Text>Please select your preferred payment gateway from this list.</Typography.Text>
                    <Typography.Text>Clicking on the link will take you to the onboarding page for that provider where
                        you'll be asked for additional information.</Typography.Text>

                    <VendorLink onClick={onboardStripe} alt={'Stripe'} src={'/assets/stripe/stripe-blurple.png'} />

                    <Typography.Text>We currently support <Typography.Link
                        href={'https://stripe.com'}>Stripe</Typography.Link> but are planning additional providers. If
                        you'd like to see a
                        particular provider added, please <Typography.Link href={'https://gauged.ai/contact'}>contact
                            us</Typography.Link>.
                    </Typography.Text>
                </Flex>
            </Layout.Content>
            <Spin size="large" spinning={busy} fullscreen />
        </Layout>
    );
}

export default OnboardingPage;
