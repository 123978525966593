import {type User, Client} from '@gauged/gauged-js';
import {getFirstQueryParam} from 'utils';

export async function confirmEmail() {
    const token = getFirstQueryParam('t');
    const accountId = getFirstQueryParam('account');
    if (!token || !accountId) {
        return new Promise((resolve) => resolve(null));
    }

    const client = new Client(accountId, {base: process.env.REACT_APP_API_URL})
    let url = client.buildUrl('/auth/confirm_email');
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({token}),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => {
            if (res.status >= 300) {
                throw res;
            }
            return res.json() as Promise<User>
        })
        .then(user => {
            return user;
        })
        .catch(() => {
            return null;
        });
}
