import {Layout, theme} from 'antd';

import Profile from './Profile';

const MainHeader = () => {
    const {
        token: { colorBgContainer},
    } = theme.useToken();

    return (
        <Layout.Header style={{ padding:'0 16px', background:colorBgContainer, display:'flex', alignItems:'center', justifyContent:'end'}}>
            <Profile />
        </Layout.Header>
    );
}

export default MainHeader;
