import {Flex, Typography} from 'antd';
import {useCurrentUser} from 'hooks/useCurrentUser';
import apiClient from 'api';

const HomePage = () => {
    const currentUser = useCurrentUser();
    const accountName = currentUser?.account ? currentUser.account.name : 'System'
    const accountId = currentUser?.account ? currentUser.account.id : apiClient.accountId;

    return (
        <Flex style={{minHeight: '100%'}} vertical justify={'center'} align={'center'}>
            <Typography.Title level={2}>Account: {accountName}</Typography.Title>
            <Typography.Paragraph>ID: {accountId}</Typography.Paragraph>
        </Flex>
    );
}

export default HomePage;
