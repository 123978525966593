import {Form, Input, Button, Typography, App} from 'antd';
import {MailOutlined} from '@ant-design/icons';

import type {User, AuthToken} from '@gauged/gauged-js';

import apiClient from 'api';

import {getErrorMessage} from 'utils';
import PasswordFormItem from 'components/forms/PasswordFormItem';

type LoginFormRequest = {
    username: string,
    password: string,
}

type LoginFormResponse = {
    token: AuthToken,
    user: User
}

const LoginForm = (props: {
    onLogin: (user: User, token: AuthToken) => void
}) => {
    const {message} = App.useApp();

    async function onFinish(values: LoginFormRequest) {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);

        try {
            const res = await fetch(
                apiClient.buildUrl('/auth/login'),
                {
                    method: 'POST',
                    body: formData,
                }
            );
            if (res.status !== 200) {
                message.error('Invalid Username or Password.');
            } else {
                const data = await res.json() as LoginFormResponse;
                props.onLogin(data.user, data.token);
            }
        } catch (e) {
            message.error(<><b>[Unknown Network Error]</b> {getErrorMessage(e)}</>);
        }
    }

    return (
        <Form
            name="login"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title>Login</Typography.Title>
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<MailOutlined/>}
                    placeholder="Email"
                    autoComplete={'email'}
                />
            </Form.Item>
            <PasswordFormItem autoComplete={'new-password'}/>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
}

export default LoginForm;
