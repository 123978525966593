import React from 'react';
import {Button, Flex, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

const PageHeader = (props: {
    title: string,
    onClick?: () => void,
    children?: React.ReactNode
}) => {
    return (
        <Flex justify={'end'} align={'center'}>
            <Typography.Title level={2}>
                {props.onClick ? <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={props.onClick}
                /> : null}
                {props.children}
            </Typography.Title>
        </Flex>
    );
};

export default PageHeader;
