import type {User} from '@gauged/gauged-js';
import apiClient from 'api';

export async function users() {
    return apiClient.get<User[]>('/users?expand=roles&expand=accounts');
}

export async function addUser(
    data: {
        email: string,
        first_name?: string | null | undefined,
        last_name?: string | null | undefined,
        roles?: string[]
    }
) {
    return apiClient.post<User>('/users?expand=roles&expand=accounts', data)
}
