import React from 'react';
import {Menu, MenuProps} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {FormOutlined, HomeOutlined, UserOutlined} from '@ant-design/icons';

const SideMenu = () => {
    const location = useLocation();

    const items: MenuProps['items'] = [
        {
            label: <Link to="/">Home</Link>,
            key: '/',
            icon: <HomeOutlined/>,
        },

    ];

    items.push(
        {
            label: <Link to="/users">Users</Link>,
            key: '/users',
            icon: <UserOutlined/>,
        }
    )
    items.push(
        {
            label: <Link to="/forms">Forms</Link>,
            key: '/forms',
            icon: <FormOutlined/>,
        }
    )

    return <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        items={items}
    />
}

export default SideMenu;
